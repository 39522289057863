<template>
  <div>
    <Doughnut :chart-options="chartOptions" :chart-data="chartData" :width="300" :height="300" />
  </div>
</template>

<script>
import { Doughnut } from "vue-chartjs/legacy";
// import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Chart as ChartJS,
  // Title,
  Tooltip,
  Legend,
  ArcElement
  // CategoryScale,
  // LinearScale,
  // BarElement,
} from "chart.js";
ChartJS.register(
  // Title,
  Tooltip,
  Legend,
  ArcElement,
  // ChartDataLabels
  // CategoryScale,
  // LinearScale,
  // BarElement
);
export default {
  props: ["chartOptions", "chartData"],
  components: {
    Doughnut
  },
  mounted() {
    this.chartData.datasets.forEach(dataset => {
      dataset.backgroundColor = dataset.backgroundColor.map(color => {
        if (color === undefined) {
          return "#9c1c8b";
        } else {
          return color;
        }
      });
    });
  }
};
</script>

<style></style>
